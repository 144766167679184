<template>
  <v-app id="home">
    <NavBar />
    <v-container class="body" fluid>
      <div class="head">
        <v-row>
          <v-col cols="5">
            <div style="position: relative" class="mt-16">
              <h2 class="text-light-blue-accent-3 font-weight-black">FACTURACIÓN EN LÍNEA</h2>
              <h2 class="text-white">MODALIDADES</h2>
              <span class="text-grey">Computarizada en Linea y Electronica en Linea</span><br />

              <v-btn tile dark color="#38b6ff" class="mt-8" variant="outlined">
                Contáctanos
              </v-btn>
            </div>
          </v-col>
          <v-col cols="2">
            <div
              style="
                position: absolute;
                z-index: 9999;
                bottom: 0;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
              "
              class="mt-16"
            >
              <v-icon>fas fa-angle-double-down</v-icon>
            </div>
          </v-col>
          <v-col cols="5">
            <div style="position: relative; z-index: 10" class="mt-16  egg2">
              <v-img src="man.png" contain max-height="302"></v-img>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-col cols="12" class="mt-16" id="about">
      </v-col>

      <div class="text-center mt-4">
        <p class="text-light-blue-accent-3 text-h4 font-weight-black">Facturación en Línea</p>
        <div style="width: 120px; margin: 0 auto">
          <v-slider
              v-model="slider2"
              color="#38b6ff"
              label="track-color"
          ></v-slider>
        </div>
      </div>
      <v-row justify="center" class="pl-2 pr-2 pt-5">
        <v-col lg="7">
          <v-row >
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Compra Venta)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Alquileres)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Sector Educativo)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Hospitales y Clínicas)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="pt-6">
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Hoteles)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
            <v-col md="3" cols="12" >
              <v-card color="#090e21" class="gradient-card">
                <v-alert
                    color="#222739"
                    variant="outlined"
                    prominent
                    class="justify-center"
                >

                  <v-card-title>
                    <v-layout class="justify-center">
                      <v-icon size="32" color="#38b6ff">fas fa-envelope</v-icon>
                    </v-layout>
                  </v-card-title>

                  <v-card-text>
                    <p class="text-color">(Servicio de Turismo)</p>
                  </v-card-text>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="text-center mt-4 pt-10 pb-6" id="caracteristicas">
        <p class="text-light-blue-accent-3 text-h4 font-weight-black">Características</p>
        <div style="width: 120px; margin: 0 auto">
          <v-slider
            v-model="slider2"
            color="#38b6ff"
            label="track-color"
          ></v-slider>
        </div>
      </div>
      <v-row justify="center" class="pl-2 pr-2">
        <v-col lg="7">
      <v-row>
        <v-col cols="12" md="6" >
          <v-card color="#090e21" class="gradient-card">
            <v-alert
                color="#222739"
                variant="outlined"
                prominent
            >
              <template v-slot:prepend>
                <v-icon size="32" color="#38b6ff" left>fas fa-envelope</v-icon>
              </template>
              <v-card-title>
                <p class="text-light-blue-accent-3">Sistema Autorizado</p>
              </v-card-title>

              <v-card-text>
                <p class="text-color">Solutio-Facturación es un sistema autorizado por el Servicio de Impuestos Nacionales de Bolivia.</p>
              </v-card-text>
            </v-alert>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" >
          <v-card color="#090e21" class="gradient-card">
            <v-alert
                color="#222739"
                variant="outlined"
                prominent
            >
              <template v-slot:prepend>
                <v-icon size="32" color="#38b6ff" left>fas fa-envelope</v-icon>
              </template>
              <v-card-title>
                <p class="text-light-blue-accent-3">Exportación de Facturas</p>
              </v-card-title>

              <v-card-text>
                <p class="text-color">Solutio-Facturación permite exportar sus facturas emitidas en formatos PDF, Excel y XML.</p>
              </v-card-text>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
        <v-row class="pt-6">
          <v-col cols="12" md="6" >
            <v-card color="#090e21" class="gradient-card">
              <v-alert
                  color="#222739"
                  variant="outlined"
                  prominent
              >
                <template v-slot:prepend>
                  <v-icon size="32" color="#38b6ff" left>fas fa-envelope</v-icon>
                </template>
                <v-card-title>
                  <p class="text-light-blue-accent-3">Su Logotipo en la Factura</p>
                </v-card-title>

                <v-card-text>
                  <p class="text-color">Personalizamos su logotipo en las facturas para la impresión de la misma.</p>
                </v-card-text>
              </v-alert>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" >
            <v-card color="#090e21" class="gradient-card">
              <v-alert
                  color="#222739"
                  variant="outlined"
                  prominent
              >
                <template v-slot:prepend>
                  <v-icon size="32" color="#38b6ff" left>fas fa-envelope</v-icon>
                </template>
                <v-card-title>
                  <p class="text-light-blue-accent-3">Envio por corréo Electrónico</p>
                </v-card-title>

                <v-card-text>
                  <p class="text-color">Envíe las facturas a sus clientes directamente desde nuestro sistema y vea cuándo el cliente ha recibido y leído su factura.</p>
                </v-card-text>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      </v-row>
      <div class="text-center mt-4 pt-15 " id="contactanos">
        <p class="text-light-blue-accent-3 text-h4 font-weight-black">Contáctanos</p>
        <div style="width: 120px; margin: 0 auto">
          <v-slider
              v-model="slider2"
              color="#38b6ff"
              label="track-color"
          ></v-slider>
        </div>
      </div>
      <v-row justify="center" class="pl-2 pr-2">
        <v-col lg="7">

      <v-col cols="12" sm="12" class="px-16" id="contact">
        <v-row>
          <v-col cols="12" sm="4">

              <v-btn
                icon="fas fa-map-marker-alt"
                color="#38b6ff"
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-color">Barrio Cuarto Centenario; Calle: Ernesto Trigo Nº 1237 </span>
              </v-col>
          <v-col cols="12" sm="4">
              <v-btn
                icon="fas fa-phone-alt"
                color="#38b6ff"
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-color">73490133 </span> <br />
              <span class="text-color">65826821</span> <br />
            </v-col>
          <v-col cols="12" sm="4">
              <v-btn
                icon="fas fa-envelope"
                color="#38b6ff"
                class="mt-10"
                variant="outlined"
              ></v-btn
              ><br />
              <span class="text-color">javierolguera@gmail.com </span> <br />
              <span class="text-color">solutiolguera@gmail.com </span> <br />
            </v-col>
        </v-row>
      </v-col>
        </v-col>
      </v-row>
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
import { defineComponent } from "vue";
import NavBar from "../components/NavBar.vue";
import FooterView from "../components/FooterView.vue";

// Components
//import HelloWorld from '../components/HelloWorld.vue';

export default defineComponent({
  name: "HomeView",
  setup() {
    return {
      slider2: 50,

      items: [
        {
          img: "i3.jpg",
        },
        {
          img: "i4.jpg",
        },
        {
          img: "i5.jpg",
        },
        {
          img: "i6.jpeg",
        },
        {
          img: "i9.jpg",
        },
        {
          img: "i8.jpg",
        },
      ],
    };
  },
  components: {
    NavBar,
    FooterView
},
});
</script>
<style scoped>
.v-container {
  padding: 16px 0 16px 0;
}
.body {
  background: #080d18;
}
.head {
  position: relative;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  height: 400px;
  width: 100%;
  color: white;
}
.head:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background:#0B1220;
  transform: skew(0deg, 6deg);
}
.head:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: #0B1220;
  transform: skew(0deg, -6deg);
}
.egg {
  display: block;
  margin-left: 100px;
  margin-top: 50px;
  width: 356px;
  height: 300px;
  background-color: #38b6ff;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}
.egg2 {
  display: block;
  margin-left: 100px;
  margin-top: 50px;
  width: 356px;
  height: 300px;
  background-color: #38b6ff;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}
.first {
  width: 100%;
  height: 280px;
  text-align: center;
  padding: 2rem 2rem;
}
.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
}
.imgHover {
  padding: 0 375px;
}
.pre {
  width: 100%;
  height: 380px;
  text-align: center;
  padding: 0 200px;
  background-color: #f5f5f5;
}
.hire {
  width: 100%;
  height: 200px;
  padding: 0 200px;
  background-color: #e9e9e9;
  margin-top: -24px;
}
.text-color{
  color: white;
}
.gradient-card {
  background: linear-gradient(180deg, #090e26, #080d19);
  color: white;
}
.color-background{
  color: #38b6ff;
}
</style>
