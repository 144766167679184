<template>
    <v-app-bar app color="#0E1526" dark flat class="px-12" >
      <v-img src="logosolutio.png" contain max-height="42"></v-img>
<!--      <v-btn >
        <v-img src="logosolutio.png" contain max-height="300"></v-img>
        <v-icon color="cyan" left class="mr-2">fas fa-signature</v-icon> AHLAN
      </v-btn>-->
      
  
      <v-spacer></v-spacer>
      <v-btn text @click="scroll('home')" class="text-light-blue-accent-3">Inicio</v-btn>
      <v-btn text @click="scroll('caracteristicas')" class="text-light-blue-accent-3" >Características</v-btn>
      <v-btn text @click="scroll('contact')" class="text-light-blue-accent-3"  >Contáctanos</v-btn>
<!--
      <v-btn text @click="scroll('services')" > Services</v-btn>
      <v-btn text @click="scroll('page')"  > Page</v-btn>
      <v-btn text @click="scroll('biog')"  > Biog</v-btn>
      <v-btn text @click="scroll('contact')"  > Contact</v-btn>-->
    </v-app-bar>
  </template>
  
  <script>
  export default {
    methods: {
      scroll(refName) {
        const element = document.getElementById(refName);
        element.scrollIntoView({ behavior: "smooth" });
      },
    },
  };
  </script>
  
  <style>
  </style>